import { DownloadOutlined } from "@ant-design/icons";
import { Button, Image } from "antd";
import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import ReactPlayer from "react-player";
import { Typography } from "antd";

const { Title } = Typography;

export default function Shop() {
  return (
    <div style={{ margin: "200px 0px", minHeight: 700 }}>
      <Title level={1} style={{ marginTop: 40 }}>
        Coming Soon
      </Title>
    </div>
  );
}
