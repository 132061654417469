import { DownloadOutlined } from "@ant-design/icons";
import { Button, Image } from "antd";
import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import ReactPlayer from "react-player";
import { Typography } from "antd";
import { NETWORK } from "../constants";

const { Title } = Typography;

export default function Video({ gameNetwork }) {
  return (
    <div style={{ margin: "100px 0px", minHeight: 700 }}>
      <div className="react-player-video">
        <ReactPlayer url="https://www.youtube.com/embed/d1HFXDExuaU" style={{ margin: "auto", borderRadius: 12 }} />
      </div>
      {gameNetwork ? (
        <Title level={3} style={{ marginTop: 40 }}>
          Connect your wallet to the {gameNetwork.name} network to play
        </Title>
      ) : null}
    </div>
  );
}
